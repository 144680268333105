export interface RedirectionProps {
  hasHyperlink?: boolean;
  linkRedirect?: string;
  typeLink?: 'a-href' | 'click-tag';
}

export const generateRedirectionHTML = ({props, id}: {props: RedirectionProps, id: string}) => {
  const { hasHyperlink = true, typeLink = 'a-href' } = props;

  if (!hasHyperlink) {
    return '';
  }

  return typeLink === 'a-href'
    ? `onClick="redirectToURL(${`'${id}'`}, event)"`
    : `onClick="redirectClickTag(${`'${id}'`}, event)"`;
};
