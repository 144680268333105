import { ImageLayerProps } from "@lidojs/design-editor";
import { RedirectionProps, generateRedirectionHTML } from "../generateRedirectionHTML";
import { generateValidURL } from "../generateValidURL";
import { PageExport, containerPremium } from "./generatePremiumHTML";

export const generateDepthCard = ({page,hasMobileFrame}: PageExport) => {
  const { ROOT: container, ...components } = page;

  const resources = {
    image1: {
      url:  (components.depthcard_image1.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.depthcard_image1.props.hyperlink,
    }
  }

  const redirectionContainer = generateRedirectionHTML(
    {
      props: container.props.hyperlink,
      id: "ROOT"
    }
  );

  return `
        <title>Creative Premium: Depth Card</title>
        ${depthCardStyles}
        <script type="text/javascript">
          var clickTag = "${generateValidURL((container?.props?.hyperlink as RedirectionProps)?.linkRedirect)}"
        </script>
      </head>
      <body ${redirectionContainer}>
        ${containerPremium(hasMobileFrame)}
            <div class="perspective">
              <div id="card">
                <img
                  src="${resources.image1.url}"
                  alt="${resources.image1.url}"
                />
              </div>
            </div>
          </div>
          ${hasMobileFrame ? "</div>" : ""}
        </div>
        ${depthCardJS1}
`
}


const depthCardJS1 = `
  <script>
    var cardElement = document.getElementById("card");
    var angle = 0; // Ángulo inicial

    function moveCard() {
      // Calcula las coordenadas x e y del movimiento circular
      var x = Math.cos(angle) * 3; // Ajusta el radio según sea necesario
      var y = Math.sin(angle) * 3;

      cardElement.style.transform =
        "rotateY(" + x + "deg) rotateX(" + y + "deg)";
      angle += 0.05; // Ajusta la velocidad y la amplitud del movimiento
    }

    // Comienza el bucle de movimiento
    setInterval(moveCard, 20); // Ajusta el intervalo según sea necesario
  </script>
`

const depthCardStyles = `
          <style>
          body {
            background-color: #fff;
          }
          .perspective {
            width: 100%;
            perspective: 1000px;
            height: 98%;
            display: flex;
            justify-content: center;
          }
          #card {
            box-shadow: 0 70px 63px -60px #494848;
            transform-style: preserve-3d;
            transition: transform 0.05s linear;
            width: 100%;
            height: 100%;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        </style>
`
