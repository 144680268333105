import { ImageLayerProps, VideoLayerProps } from "@lidojs/design-editor";
import { generateChildComponent } from "../generateChildComponent";
import { RedirectionProps, generateRedirectionHTML } from "../generateRedirectionHTML";
import { generateValidURL } from "../generateValidURL";
import { videoControlStyles } from "../stylesToExport";
import { PageExport, containerPremium } from "./generatePremiumHTML";

export const generateVideoBox = ({page,hasMobileFrame}: PageExport) => {
  const { ROOT: container, ...components } = page;

  const resources = {
    image1: {
      url:  (components.videobox_image1.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.videobox_image1.props.hyperlink,
    },
    video1: {
      url:  (components.videobox_video1.props as unknown as VideoLayerProps).video?.url,
      props: components.videobox_video1.props as unknown as VideoLayerProps,
      hyperlink: components.videobox_video1.props.hyperlink,
    }
  }

  const redirectionContainer = generateRedirectionHTML(
    {
      props: container.props.hyperlink,
      id: "ROOT"
    }
  );

  const videoHTML = generateChildComponent(components.videobox_video1, "videoBox_video1");
  const hasVideoControls = (components.videobox_video1.props as unknown as VideoLayerProps).video.controls

  return `
      <title>Creative Premium: Videobox</title>
      <script type="text/javascript">
        var clickTag = "${generateValidURL((container?.props?.hyperlink as RedirectionProps)?.linkRedirect)}";
      </script>
        <style>
          body{
            background-color: rgb(0, 0, 0);
          }
          #ROOT{
              background-color: rgb(0, 0, 0)!important;
          }
          ${hasVideoControls ?
            videoControlStyles : ''
          }
        </style>
    </head>
    <body ${redirectionContainer}>

    ${containerPremium(hasMobileFrame)}
        <div data-id="ff7a3c45-e48c-43b3-a3b5-152f7e35e954"
          data-locked="false"
          data-type="ImageLayer"
          data-child='[]'
          data-parent="ROOT"
          class=""
          style="width: 100%;
          height: 70%;
          z-index: 1;
          position: absolute; left: 0%; top: 30%;">

          <img src="${resources.image1.url}" alt="" width="100%" height="100%">
        </div>
        <div data-id="d4f50bcc-cc98-4223-beb2-2e048051cb25"
            data-locked="false"
            data-type="VideoLayer"
            data-child='[]'
            data-parent="ROOT"
            class=""
            style="width: 100%;
            height: 30%;
            z-index: 2;
            position: absolute; left: 0%; top: 0%;">
              ${videoHTML}
        </div>
      </div>
      ${hasMobileFrame ? "</div>" : ""}
    </div>
  `
}
