import { LayerComponentProps, SerializedLayer } from '@lidojs/design-core';
import { filterProps } from './filterProps';
import { generateChildComponent } from './generateChildComponent';
import { generateClasses } from "./generateClasses";
import { generateRedirectionHTML } from './generateRedirectionHTML';
import { generateStyles } from './generateStyles';


export const generateComponent = (children: {
  component: SerializedLayer;
  zPosition: number;
  id: string;
  container: SerializedLayer;
  groupContainer?: SerializedLayer;
}) => {
  const { component, zPosition, id, container, groupContainer } = children;

  const child = JSON.stringify(component.child);
  const { props } = component;

  const validProps = filterProps(props);
  let styles = generateStyles(validProps as LayerComponentProps, zPosition, container, groupContainer);
  const classes = generateClasses(validProps as unknown as LayerComponentProps)

  if (props.shape) {
    styles += ` background-color: ${props.color};`;
  }

  if (
    props?.typeCarousel === 'carousel1' ||
    props?.typeCarousel === 'carousel8'
  ) {
    styles += `display: flex; flex-direction: column; gap: 2%;`;
  }

  const redirectionHTML = generateRedirectionHTML({
    props: validProps.hyperlink,
    id
  });

  const childComponent = generateChildComponent(component, id);

  return `<div data-id="${id}"
            data-locked="${component.locked}"
            data-type="${component.type.resolvedName}"
            data-child='${child}'
            data-parent="${component.parent}"
            class="${classes}"
            ${redirectionHTML}
            style="${styles}">
              ${childComponent}
          </div>`;
};
