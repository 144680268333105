import { SerializedLayers } from "@lidojs/design-core";
import { RedirectionProps } from "./generateRedirectionHTML";
import { generateValidURL } from "./generateValidURL";

export function extractLinks(data: SerializedLayers): string {
  const links = {};

  Object.keys(data).forEach(key => {
    const layer = data[key];

    // links on components
    if ((layer.props?.hyperlink as RedirectionProps)?.hasHyperlink) {
      links[key] = generateValidURL((layer.props?.hyperlink as RedirectionProps)?.linkRedirect);
    }

    // links on carousel slides
    if (layer.type?.resolvedName === "CarouselLayer") {
      const carouselItems = layer.props?.itemsCarousel || [];
      if (Array.isArray(carouselItems)) {
        carouselItems.forEach((item, index) => {
          if (item.hasHyperlink) {
            links[item.id] = generateValidURL(item.linkRedirect);
          }
        });
      }
    }
  });

  return `
    const clickthrough = ${JSON.stringify(links)};
  `;
}
